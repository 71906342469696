export default class Song {
    name;
    videoId;
    channel;
    constructor(name, videoId, channel) {
        this.name = name;
        this.videoId = videoId;
        this.channel = channel;
    }
}
