export default class RequestSpinSlotMachineComposer {
    header = "spin_slot_machine";
    data;
    constructor(item_id, bet) {
        this.data = {
            "itemId": item_id,
            "bet": bet
        };
    }
}
