export default class SlotItem {
    name;
    value;
    image;
    constructor(name, value, image) {
        this.name = name;
        this.value = value;
        this.image = image;
    }
}
