export default class Constants {
    static avatarImagerUrl = "https://habbo.es/habbo-imaging/avatarimage?figure=";
    static youtubeApiUrl = "https://api-music.herokuapp.com/search?keyword=";
    //private static youtubeApiUrl =  "https://habinhotell.no/youtube.php?keyword="
    static getAvatarImagerUrl() {
        return Constants.avatarImagerUrl;
    }
    static getYoutubeApiUrl() {
        return Constants.youtubeApiUrl;
    }
}
