import User from '../models/User';
const state = {
    user: new User(),
    credits: 0
};
const namespaced = true;
const mutations = {
    setUsername(state, val) {
        state.user.username = val;
    },
    setUser(state, val) {
        state.user = val;
    },
    setLook(state, val) {
        state.user.look = val;
    },
    setCredits(state, val) {
        state.credits = val;
    },
    setId(state, id) {
        state.user.id = id;
    }
};
export const session = {
    namespaced,
    state,
    mutations
};
