import { render, staticRenderFns } from "./CommandsComponent.vue?vue&type=template&id=dd395dac&scoped=true&"
import script from "./CommandsComponent.vue?vue&type=script&lang=ts&"
export * from "./CommandsComponent.vue?vue&type=script&lang=ts&"
import style0 from "./CommandsComponent.vue?vue&type=style&index=0&id=dd395dac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd395dac",
  null
  
)

export default component.exports