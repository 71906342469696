export default class User {
    username;
    id;
    look;
    constructor() {
        this.username = "";
        this.id = 0;
        this.look = "";
    }
}
