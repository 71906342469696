export default class AddSongComposer {
    header = "add_song";
    data;
    constructor(song) {
        this.data = {
            "name": song.name,
            "videoId": song.videoId,
            "channel": song.channel
        };
    }
}
