export default class EditTvComposer {
    header = "edit_tv";
    data;
    constructor(ItemId, VideoId) {
        this.data = {
            "itemId": ItemId,
            "videoId": VideoId
        };
    }
}
