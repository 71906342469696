import InterfaceManager from '@/interface/InterfaceManager';
import CommunicationManager from '@/communication/CommunicationManager';
import InteractionManager from '@/interaction/InteractionManager';
export default class App {
    static _interfaceManager;
    static _communicationManager;
    static _interactionManager;
    static init() {
        App._interfaceManager = new InterfaceManager();
        App._communicationManager = new CommunicationManager();
        App._interactionManager = new InteractionManager();
    }
    static get interfaceManager() {
        return App._interfaceManager;
    }
    static get communicationManager() {
        return App._communicationManager;
    }
    static get interactionManager() {
        return App._interactionManager;
    }
}
