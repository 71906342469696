const state = {
    open: false,
    channel: ""
};
const namespaced = true;
const mutations = {
    setOpen(state, open) {
        state.open = open;
    },
    setChannel(state, channel) {
        state.channel = channel;
    }
};
export const twitchplayer = {
    namespaced,
    state,
    mutations
};
